import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import Swal from 'sweetalert2';
import Logo from '../../assets/notes.png';

export default function ToDos() {
          const [toDos, setToDos] = useState(localStorage.getItem('KToDos') ? JSON.parse(localStorage.getItem('KToDos') as any) : []);
          const [inputValue, setInputValue] = useState('');

          const handleInputChange = (e: any) => {
                    setInputValue(e.target.value);
          };

          useEffect(() => {
                    localStorage.setItem('KToDos', JSON.stringify(toDos));
          }, [toDos]);

          const handleAddTodo = () => {
                    if (inputValue === '') return toast.error('Please type a task');
                    const newToDo = {
                              id: Date.now(),
                              todo: inputValue,
                              wordCount: inputValue.trim().split(/\s+/).length,
                              characterCount: inputValue.length,
                              completed: false,
                              date: Date.now(),
                              author: 'KToDos',
                    };
                    const newToDos = [...toDos, newToDo];
                    setToDos(newToDos);
                    toast.success('New todo added!', {
                              position: 'top-right',
                    });
                    localStorage.setItem('KToDos', JSON.stringify(toDos));
                    setInputValue('');
          };

          const handleDeleteTodo = (id: any) => {
                    Swal.fire({
                              text: 'Are you sure to delete?',
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Yes, delete it!'
                    }).then((result: any) => {
                              if (result.isConfirmed) {
                                        const updatedToDos = toDos.filter((todo: any) => todo.id !== id);
                                        setToDos(updatedToDos);
                                        localStorage.setItem('KToDos', JSON.stringify(toDos));
                                        Swal.fire({
                                                  title: 'Deleted!',
                                                  text: 'Your todo has been deleted.',
                                                  icon: 'success',
                                                  timer: 1000,
                                                  showConfirmButton: false,
                                        });
                              }
                    }
                    )
          };

          const handleCopyTodo = (id: any) => {
                    const updatedToDos = toDos.filter((todo: any) => todo.id === id);
                    navigator.clipboard.writeText(updatedToDos[0].todo);
                    toast.success('Copied to clipboard!', {
                              position: 'top-right',
                    });
          }

          const handleCompleteTodo = (id: any, value: any) => {
                    const updatedToDos = [...toDos];
                    const todoIndex = updatedToDos.find((todo: any) => todo.id === id);
                    if (value) {
                              todoIndex.completed = true;
                              setToDos(updatedToDos);
                    } else {
                              todoIndex.completed = false;
                              setToDos(updatedToDos);
                    }
                    localStorage.setItem('KToDos', JSON.stringify(toDos));
          };

          const formatDate = (value: any) => {
                    if (!value) return "";

                    const date = new Date(value);
                    const monthNames = [
                              "Jan",
                              "Feb",
                              "March",
                              "April",
                              "May",
                              "Jun",
                              "Jul",
                              "Aug",
                              "Sept",
                              "Oct",
                              "Nov",
                              "Dec",
                    ];

                    const dayNames = [
                              "Sun",
                              "Mon",
                              "Tue",
                              "Wed",
                              "Thu",
                              "Fri",
                              "Sat",
                    ];

                    let hrs = date.getHours() as any;
                    let amPm = hrs >= 12 ? "PM" : "AM";
                    hrs = hrs % 12;
                    hrs = hrs ? hrs : 12;
                    hrs = hrs < 10 ? "0" + hrs : hrs;

                    let min = date.getMinutes() as any;
                    min = min < 10 ? "0" + min : min;

                    let sec = date.getSeconds() as any;
                    sec = sec < 10 ? "0" + sec : sec;

                    let day = date.getDate();
                    const month = monthNames[date.getMonth()];
                    const year = date.getFullYear();

                    const dayName = dayNames[date.getDay()];

                    return `${hrs}:${min}:${sec} ${amPm}, ${dayName} ${day} ${month} ${year}`;
          };

          return (
                    <div className="container w-full px-4 md:px-0 md:w-2/3 lg:w-1/3 mx-auto py-2 md:py-16">
                              <h1 className="text-2xl font-bold text-center mb-8 my-4 select-none">
                                        <img src={Logo} alt="KToDos" className='w-8 inline-block mr-2' />
                                        KToDo - <span className='text-xs'>a simple todo app</span>
                              </h1>
                              <div className='glass p-2 md:p-5 rounded-xl'>
                                        <div className={`${toDos.length > 0 ? 'mb-6 lg:mb-10' : ''} flex w-full md:w-3/4 lg:w-2/3 mx-auto`}>
                                                  <input
                                                            type="text"
                                                            className="input focus:outline-none glass pl-5 p-2 py-8 flex-grow rounded-xl rounded-tr-none rounded-br-none w-full"
                                                            placeholder="Type a task & press enter"
                                                            value={inputValue}
                                                            onChange={handleInputChange}
                                                            onKeyDown={(e) => {
                                                                      if (e.key === 'Enter') {
                                                                                handleAddTodo();
                                                                      }
                                                            }}
                                                  />
                                                  <button
                                                            className="glass uppercase text-xs md:text-[14px] font-semibold py-2 px-4 rounded-xl rounded-tl-none rounded-bl-none"
                                                            onClick={handleAddTodo}
                                                  >
                                                            Add
                                                  </button>
                                        </div>
                                        {
                                                  toDos.length > 0 && (
                                                            <div className='text-center mb-5 mr-2'>
                                                                      <p className='text-sm'>{toDos.length} {toDos.length === 1 ? 'task' : 'tasks'} remaining</p>
                                                            </div>
                                                  )
                                        }
                                        {toDos.length > 0 ? (
                                                  <ul className='max-h-[450px] md:max-h-[500px] overflow-y-auto'>
                                                            {toDos?.slice().reverse().map((todo: any) => (
                                                                      <div className='flex justify-between items-center px-3 glass mb-2 rounded-xl' key={todo.id}>
                                                                                <div className='px-0 py-4 select-none'>
                                                                                          <p className='text-xs pb-3 text-gray-500'>{todo.characterCount} character(s), {todo.wordCount} word(s)</p>
                                                                                          <li
                                                                                                    key={todo.id}
                                                                                                    className={`${todo.completed ? 'line-through' : ''} select-none cursor-pointer rounded-xl flex justify-start items-center gap-3 pr-3 md:pr-0`}
                                                                                                    onClick={() => handleCompleteTodo(todo.id, !todo.completed)}
                                                                                          >
                                                                                                    <input type="checkbox" checked={todo.completed} onChange={
                                                                                                              (e) => handleCompleteTodo(todo.id, e.target.checked)
                                                                                                    } className="checkbox checkbox-xs glass" />
                                                                                                    {todo.todo}
                                                                                          </li>
                                                                                          <p className='text-xs pt-3 text-gray-500'><span className='font-bold'>Date & Time: </span>{formatDate(todo.date)}</p>
                                                                                </div>
                                                                                <div className='flex items-center gap-2'>
                                                                                          <button
                                                                                                    className="glass btn-ghost rounded-xl px-2 hover:bg-transparent"
                                                                                                    onClick={() => handleCopyTodo(todo.id)}
                                                                                          >
                                                                                                    <i className='bx bx-clipboard text-lg'></i>
                                                                                          </button>
                                                                                          <button
                                                                                                    className="glass btn-ghost rounded-xl text-red-500 hover:text-red-600 px-2 hover:bg-transparent"
                                                                                                    onClick={() => handleDeleteTodo(todo.id)}
                                                                                          >
                                                                                                    <i className='bx bx-trash-alt text-lg'></i>
                                                                                          </button>
                                                                                </div>
                                                                      </div>
                                                            ))}
                                                  </ul>
                                        ) : (
                                                  <div className='text-center my-5'>
                                                            <p className='text-gray-500'>No task added yet</p>
                                                  </div>
                                        )}
                              </div>
                    </div>
          )
}
