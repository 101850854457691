import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import ToDos from './pages/ToDos/ToDos';

const App: React.FC = () => {
  useEffect(() => {
    const appContainer = document.getElementById('appContainer');
    const data = localStorage.getItem('KToDos') ? JSON.parse(localStorage.getItem('KToDos') as any) : [];
    if (data?.length > 5) {
      appContainer?.classList.add('h-screen');
    } else {
      appContainer?.classList.add('h-screen');
    }
  }, []);

  return (
    <div id='appContainer'>
      <ToDos />
      <Toaster />
    </div>
  );
};

export default App;
